.side-drawer {
  transform: translateX(200%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}
.side-drawer-items {
  font-family: "Sedgwick Ave Display", cursive;
}
