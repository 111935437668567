.about {
  min-height: 100vh;
  /* padding-top: 15% */
  /* border: 4px solid red; */
  /* padding-bottom: 500px; */
  /* height: 1000px; */
  /* max-height: 10; */
  /* min-height: 100%; */
  /* background: red; */
}
/* @media (max-width: 750px) {
  .about {
    min-height: 100vh;
    /* display: flex;
    flex-direction: column; */
/* min-width: 0; */
/* }
}  */
.reasons-1 {
  font-family: "Sedgwick Ave Display", cursive;
  color: white;
  font-size: 2.3rem;
  padding-top: 7%;
}

@media (max-width: 750px) {
  .text-left {
    /* margin: 0; */
    width: 100%;
  }
  .about {
    padding-top: 15%;
  }
}
.text-para {
  width: 50%;
  font-family: "Shadows Into Light", cursive;
}
@media (max-width: 450px) {
  .text-para {
    width: 100%;
  }
}
