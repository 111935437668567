.App {
  /* display: flex; */
  text-align: center;
  position: relative;
  min-height: 100vh;
  /* max-height: 100vh; */
  background: #212934;
  /* width: 100vh; */
  /* min-width: 100vh; */
  /* height: 100%; */
  /* height: 100vh; */
  /* width: 100%; */
  /* padding-left: 25%; */
}
/* @media (max-width: 750px) {
  .App {
    width: 100%;
  }
} */

.Content {
  width: 100%;
}
@media (max-width: 750px) {
  .App {
    /* min-width: 100vh; */
  }
  .Content {
    /* min-width: 100vw; */
    /* width: 100%; */
  }
}

.Content {
  padding-bottom: 2.5rem;
  /* padding-left: 25%; */
  /* display: flex; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
