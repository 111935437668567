@media (max-width: 750px) {
  .picture-gallery {
    padding-top: 7%;
    flex-direction: column;
  }
}
@media (max-width: 450px) {
  .picture-gallery-header {
    padding-top: 20%;
  }
}
.picture-gallery {
  padding-top: 9%;
  padding-bottom: 5%;
  min-width: 100vw;
}
.picture-container {
  position: relative;
  /* width: 50%; */
}
.picture-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #73bf32;
}
.picture-container:hover .picture-overlay {
  opacity: 0.75;
}
.picture-description {
  color: white;
  font-family: "Shadows Into Light", cursive;
  font-size: 1.6rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* text-align: center; */
}
.picture-gallery-header {
  font-family: "Sedgwick Ave Display", cursive;
  color: white;
  padding-bottom: 3%;
}
@media (max-width: 450px) {
  .picture-row {
    display: flex;
    flex-direction: column;
    padding-bottom: 4%;
  }
}
/* .circle-dots { */
/* display: flex;
  flex-direction: column;
}
.pictures {
  display: flex;
  flex-direction: row;
}
.pic-arrows {
  display: flex;
  justify-content: space-evenly;
  /* display: none; */
