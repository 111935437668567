.blog {
  padding-top: 9%;
  min-height: 100vh;
  min-width: 100vw;
}
.blog-header {
  font-family: "Sedgwick Ave Display", cursive;
  font-size: 3rem;
  color: #ffffff;
  padding-bottom: 3%;
}
@media (max-width: 750px) {
  .blog-header {
    padding-top: 23%;
  }
  .blog {
    flex-direction: column;
  }
  .text-left {
    flex-direction: column;
  }
  .arrow {
    padding-top: 15%;
  }
  .blog-text {
    text-align: center;
  }
}
.blog-text {
  font-family: "Shadows Into Light", cursive;
}
