/* .schoolRecs {
  display: flex;
  align-items: column;
} */
.senpais-articles {
  font-family: "Sedgwick Ave Display", cursive;
  font-size: 3rem;
  color: #ffffff;
  padding-bottom: 3%;
}
.arrow {
  padding-top: 7%;
}
.articles-front {
  min-width: 100vw;
}
@media (max-width: 375px) {
  .arrow {
    padding-top: 20%;
  }
}
@media (max-width: 750px) {
  .senpais-articles {
    padding-top: 17%;
  }
  .article-cards {
    display: flex;
    flex-direction: column;
    padding-bottom: 15%;
  }
  .p-0 {
    padding-bottom: 10%;
  }
  .relife-card {
    padding-bottom: 37%;
  }
  .school-recs {
    flex-direction: column;
  }
  .school-recs-reverse {
    flex-direction: column-reverse;
  }
}
.schoolRecs {
  font-family: "Shadows Into Light", cursive;
  color: #ffffff;
  font-size: 2.9rem;
}
.reasons {
  font-family: "Shadows Into Light", cursive;
  color: #ffffff;
  font-size: 2.9rem;
}
.strangerThings {
  font-family: "Shadows Into Light", cursive;
  color: #ffffff;
  font-size: 2.9rem;
}

.jumbotron-header {
  font-family: "Shadows Into Light", cursive;
  padding: 0;
  background: "#73bf32";
}
.read-me {
  color: black;
}
