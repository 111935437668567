.videos-background {
  min-width: 100vw;
}
.traphouse-pod {
  font-family: "Sedgwick Ave Display", cursive;
  font-size: 3rem;
  color: #ffffff;
  padding-bottom: 3%;
}
.videos-header {
  font-family: "Shadows Into Light", cursive;
  color: #ffffff;
  font-size: 2.9rem;
  padding: 0;
}
.videos {
  font-family: "Sedgwick Ave Display", cursive;
  padding-top: 10%;
}
@media (max-width: 750px) {
  .stupid-video {
    display: flex;
    flex-direction: column;
  }
  .videos {
    padding-top: 23%;
  }
}
