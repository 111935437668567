.column-info {
  padding: 1rem;
  background-color: #33b5e5;
  /* border: 2px solid #fff; */
  color: #fff;
  /* color: black; */
  text-align: center;
}
.senpai {
  font-family: "Sedgwick Ave Display", cursive;
  font-size: 8rem;
}

.traphouse {
  padding-top: 7%;
  font-family: "Sedgwick Ave Display", cursive;
  font-size: 8rem;
  color: white;
}

@media (max-width: 750px) {
  .traphouse {
    padding-top: 15%;
    font-size: 4rem;
  }
}
.japanese {
  font-size: 2rem;
}

@media (max-width: 750px) {
  .landing-page-content {
    min-width: 100vh;
    /* min-width: 100vh; */
  }
  .stupid-video {
    display: flex;
    flex-direction: column;
    /* padding-bottom: %; */
  }
}
.stupid-video {
  padding-bottom: 10%;
}

.text-center {
  padding-top: 5%;
  font-family: "Shadows Into Light", cursive;
}
@media (max-width: 750px) {
  .text-center {
    /* padding-top: 7%; */
    font-size: 1.5rem;
  }
  .mdb-cards {
    flex-direction: column;
  }
  .mdb-cards-column {
    padding-bottom: 5%;
  }
}
.shadow {
  display: none;
  font-family: "Shadows Into Light", cursive;
  padding-top: 2.5%;
  text-align: center;
  /* font-family: "Permanent Marker", cursive; */
  font-size: 2.2rem;
  color: white;
  /* color: yellow; */
}
@media (max-width: 450px) {
  .shadow {
    display: block;
  }
  .traphouse {
    padding-top: 21%;
  }
}
/* .thought {
  display: flex;
  background-color: #fff;
  padding: 20px;
  border-radius: 30px;
  min-width: 40px;
  max-width: 220px;
  min-height: 40px;
  margin: 20px;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.thought:before,
.thought:after {
  content: "";
  background-color: #fff;
  border-radius: 50%;
  display: block;
  position: absolute;
  z-index: -1;
}
.thought:before {
  width: 44px;
  height: 44px;
  top: -12px;
  left: 28px;
  box-shadow: -50px 30px 0 -12px #fff;
}
.thought:after {
  bottom: -10px;
  right: 26px;
  width: 30px;
  height: 30px;
  box-shadow: 40px -34px 0 0 #fff, -28px -6px 0 -2px #fff,
    -24px 17px 0 -6px #fff, -5px 25px 0 -10px #fff;
} */
/* .tester {
  font-family: "Sedgwick Ave Display", cursive;
  color: "green"; */
/* } */
.landing-card-title {
  font-family: "Sedgwick Ave Display", cursive;
  font-size: 2rem;
}
.landing-card-text {
  font-family: "Shadows Into Light", cursive;
  font-size: 3rem;
  /* font-size: 20px; */
}
.landing-card-text-body {
  font-size: 1.2rem;
}
.p-2 {
  font-family: "Shadows Into Light", cursive;
  color: white;
  font-size: 1.4rem;
}
.d-f {
  padding-bottom: 65%;
}
